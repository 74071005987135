<template>
  <v-main>
    <heads />
    <v-container class="mt-16" >
        <v-row class="mt-10">
          <v-spacer></v-spacer>  
          <v-col >
            <v-divider color="orange"></v-divider>
            <h2 class="mt-2 text-h4 font-weight-bold" align="center" justify="center">Entrar em contacto</h2>
          </v-col>
          <v-spacer></v-spacer>  
        </v-row>
      <v-row class="mt-7">
        <v-spacer></v-spacer>
        <v-col cols="10">
          <v-form class="mt-5">
            <v-text-field  color="secondary" placeholder="Nome" outlined ></v-text-field>
            <v-text-field  color="secondary" placeholder="E-mail" outlined ></v-text-field>
            <v-textarea solo auto-grow outlined color="secondary" label="Mensagem" placeholder="Mensagem" ></v-textarea>
            <v-btn block elevation="2" color="success">Enviar mensagem</v-btn>
          </v-form>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row class="mt-10">
        <v-spacer></v-spacer>
        <v-col cols="3">
          <v-row>
            <v-avatar color="#FFC000" size="60">
              <v-icon color="white" >fas fa-phone-alt</v-icon>
            </v-avatar>
            <v-col class="mt-n2">
              <div class="text-Caption font-weight-bold">Telemóvel</div>
              <div>915762128</div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3">
          <v-row>
            <v-avatar color="#FFC000" size="60">
              <v-icon color="white" >fas fa-at</v-icon>
            </v-avatar>
            <v-col class="mt-n2">
              <div class="text-Caption font-weight-bold">E-mail</div>
              <div>aeestg@estg.ipvc.pt</div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3">
          <v-row>
            <v-avatar color="#FFC000" size="60">
              <v-icon color="white" >fas fa-map-marker-alt</v-icon>
            </v-avatar>
            <v-col class="mt-n2">
              <div class="text-Caption font-weight-bold">Morada</div>
              <div>Avenida do Atlântico, nº 644 <br> 4900-348, Viana do Castelo</div>
            </v-col>
          </v-row>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row class="mt-10">
        <v-spacer></v-spacer>
        <v-col cols="10">
          <GmapMap :center="{lat:41.694596, lng:-8.846898}" :zoom="15"  map-type-id="satellite" style="width: 100%; height: 400px" > 
            <gmap-marker  :position="{lat:41.694596, lng:-8.846898}"    > </gmap-marker>
          </GmapMap>
        </v-col> 
        <v-spacer></v-spacer>
      </v-row>
    </v-container>
    <footers class="mt-10"/>
  </v-main>
</template>

<script>
  import heads from '../components/header.vue'
  import footers from '../components/footer.vue'
  import {gmapApi} from 'vue2-google-maps'

  export default {
    name: 'Contactos',

    components: {
      heads,
      footers,
    },
    computed: {
      google: gmapApi
    }
  }
</script>
