<template>
  <v-main>
    <heads />
    <v-container class="mt-16" >
      <v-row class="mt-10">
        <v-spacer></v-spacer>  
        <v-col >
          <v-divider color="orange"></v-divider>
          <h2 class="mt-2" align="center" justify="center">Próximos eventos</h2>
        </v-col>
        <v-spacer></v-spacer>  
      </v-row>
      <v-row class="mt-5">
        <v-spacer></v-spacer>  
        <v-col class="col-lg-10 col-xl-8">
          <h2 class="mt-2" align="center" justify="center">Sem próximos eventos</h2>
        </v-col>
        <v-spacer></v-spacer>  
      </v-row>
       <v-row class="mt-10">
        <v-spacer></v-spacer>  
        <v-col >
          <v-divider color="orange"></v-divider>
          <h2 class="mt-2" align="center" justify="center">Eventos passados</h2>
        </v-col>
        <v-spacer></v-spacer>  
      </v-row>
      <v-row class="mt-5">
        <v-spacer></v-spacer> 
        <v-col class=" col-lg-10 col-xl-8">
           <v-row v-for="evento in eventosPassados" :key="evento">
            <v-col class="col-xs-1 col-sm-3 col-lg-2">
              <v-sheet style="border: 2px solid #E09100; height:100% !important; max-height: 150px;" >
                <h2 class="font-weight-black text-h2 pt-4 secondary--text" align="center" justify="center" >{{ getDate(evento.data).getDate() }}</h2>
                <h4 class="pt-1 secondary--text" align="center" justify="center">{{ getMonth(getDate(evento.data).getMonth()) }}</h4>
                <h5 class=" secondary--text" align="center" justify="center">{{ getDate(evento.data).getFullYear() }}</h5>
              </v-sheet>
            </v-col>
            <v-col class="col-xs-2 col-sm-5 col-lg-6">
                <h2 class="font-weight-bold text-h5 " align="left " justify="center" >{{evento.nome}}</h2>
                <h6 class="pt-1 pl-1" align="left" justify="center">{{evento.local}}</h6>
                <p class="text-body-2" style="text-align: justify; text-justify: inter-word;">{{evento.descricao}}</p>
            </v-col>
            <v-col class="col-xs-2 col-sm-4 col-lg-4">
              <v-img :src="getImgUrl(evento.img)" :aspect-ratio="1"></v-img>
            </v-col>
          </v-row>
        </v-col>
        <v-spacer></v-spacer>  
      </v-row>
    </v-container>
    <footers class="mt-10"/>
  </v-main>
</template>

<script>
  import heads from '../components/header.vue'
  import footers from '../components/footer.vue'

  export default {
    name: 'Eventos',

    components: {
      heads,
      footers
    },
    data: () => ({
      eventosPassados: [
        {
          nome: 'Política (não) importa',
          local: 'Online',
          descricao: 'A partir de hoje, nunca mais te sentirás constrangido em conversas políticas por não estares devidamente informado.' +
                      'Queremos reforçar a ideia de que todos nós devemos saber o suficiente, sobre política, para podermos votar em consciência nas eleições.' +
                      'Por isso, convidamos a estares connosco na sessão de esclarecimentos, este sábado dia 15 de maio, às 11h onde teremos o prazer de receber a política (não) importa!',
          data: '2021-01-15',
          img: 'politicaNaoImporta.png',
        }
      ]
    }),
    methods: {
      getImgUrl(pic) {
        if(pic == ''){
          return require('../assets/logo.png')
        }
        return require('../assets/eventos/'+pic)
      },
      getDate(date){
        var arrDate = date.split('-')
        console.table(arrDate)
        return new Date(arrDate[0],arrDate[1]-1,arrDate[2],0,0,0)
      },
      getMonth(nrMonth){
        var month = new Array();
          month[0] = "Janeiro";
          month[1] = "Fevereiro";
          month[2] = "Março";
          month[3] = "Abril";
          month[4] = "Maio";
          month[5] = "Junho";
          month[6] = "Julho";
          month[7] = "Agosto";
          month[8] = "Setembro";
          month[9] = "Outubro";
          month[10] = "Novembro";
          month[11] = "Dezembro";
          return month[nrMonth];
      }
    },
  }
</script>
