<template>
  <v-footer elevation="4" color="#383A3C">
      <v-col class="text-center white--text" cols="12" >
        {{ new Date().getFullYear() }} — <strong>AE ESTG </strong>
      </v-col>  
      <v-col class="text-center" cols="12" >
        <v-btn href="https://www.facebook.com/aeestgipvc" target="_blank" class="ma-5" icon>
          <v-icon color="secondary" large>fab fa-facebook</v-icon>
        </v-btn>
        <v-btn href="https://www.instagram.com/aeestgipvc" target="_blank" icon>
        <v-icon color="secondary" large>fab fa-instagram</v-icon>
        </v-btn>
      </v-col>
  </v-footer>
</template>
<script>
  export default {
    name: 'footer',
    data: () => ({
    }),
    methods: {
    },
  }
</script>

<style scoped>

</style>