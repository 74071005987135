<template>
<v-carousel cycle  hide-delimiter-background show-arrows-on-hover >
    <v-carousel-item
      v-for="(slide, i) in imgs"
      :key="i"
    >
      <v-img :src="getImgUrl(slide)" ></v-img>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
  export default {
    name: 'Carousel',
    data: () => ({
      colors: [
        'indigo',
        'warning',
        'pink darken-2',
        'red lighten-1',
        'deep-purple accent-4',
      ],
      slides: [
        'First',
        'Second',
        'Third',
        'Fourth',
        'Fifth',
      ],
      imgs: ['P5181325.jpg','P5181338.jpg','P5181348.jpg','P5181375.jpg','P5181375.jpg','P5181380.jpg','P5181386.jpg','P5181394.jpg']
    }),
    methods: {
      getImgUrl(pic) {
        return require('../assets/galeria/'+pic)
      },
    }
  }
</script>

<style scoped>

</style>