<template>
  <v-main>
    <heads />
    <v-container class="mt-16" >
      <v-row class="mt-10">
        <v-spacer></v-spacer>  
        <v-col >
          <v-divider color="orange"></v-divider>
          <h2 class="mt-2 text-h4 font-weight-bold" align="center" justify="center">Presidentes</h2>
        </v-col>
        <v-spacer></v-spacer>  
      </v-row>
      <v-timeline class="mt-10 pt-0 pb-0" align-top>
        <v-timeline-item  color="black" small>

        </v-timeline-item>
        <v-timeline-item v-for="presi in presidentes" :key="presi" large >
          <template  v-slot:icon>
            <v-avatar color="secondary">
              <v-icon v-if="getImgUrl(presi.img) == ''">fas fa-user-tie</v-icon>
              <v-img :src="getImgUrl(presi.img)" v-else></v-img>
            </v-avatar>
          </template>
          <template v-slot:opposite>
            <span>{{presi.ano}}</span>
          </template>
          <v-card class="elevation-2">
            <v-card-title class="text-subtitle-1" >
              {{presi.nome}}
            </v-card-title>
          </v-card>
        </v-timeline-item>
        <v-timeline-item class="pb-0" color="black" small>
        </v-timeline-item>
      </v-timeline>
    </v-container>
    <footers class="mt-10"/>
  </v-main>
</template>

<script>
  import heads from '../components/header.vue'
  import footers from '../components/footer.vue'

  export default {
    name: 'Historia',

    components: {
      heads,
      footers
    },
    data: () => ({
      presidentes: [
        {
          nome: 'António Machado',
          ano: '1991',
          img: '',
        },
        {
          nome: 'Vitor Fernandes',
          ano: '1992',
          img: '',
        },
        {
          nome: 'Vitor Fernandes',
          ano: '1993',
          img: '',
        },
        {
          nome: 'Paulo Carrança',
          ano: '1994',
          img: '',
        },{
          nome: 'Pedro Pereira',
          ano: '1995',
          img: '',
        },
        {
          nome: 'David Faria',
          ano: '1996',
          img: '',
        },
        {
          nome: 'Eduardo Silva',
          ano: '1997',
          img: '',
        },
        {
          nome: 'Vanda Rodrigues',
          ano: '1998',
          img: '',
        },
        {
          nome: 'Ricardo Rodrigues',
          ano: '1999',
          img: '',
        },
        {
          nome: 'Pedro Rocha',
          ano: '2000',
          img: '',
        },
        {
          nome: 'João Oliveira',
          ano: '2001',
          img: '',
        },
        {
          nome: 'Paula Chaves',
          ano: '2002',
          img: '',
        },{
          nome: 'Bruno Esteves',
          ano: '2003',
          img: '',
        },
        {
          nome: 'Rui Silva',
          ano: '2004',
          img: '',
        },
        {
          nome: 'Hugo Delgado',
          ano: '2005',
          img: '',
        },
        {
          nome: 'Ana Silva',
          ano: '2006',
          img: '',
        },
        {
          nome: 'Óscar Rodrigues',
          ano: '2007',
          img: '',
        },
        {
          nome: 'Ricardo Bento',
          ano: '2008',
          img: '',
        },{
          nome: 'João Carvalho',
          ano: '2009',
          img: '',
        },
        {
          nome: 'João Carvalho',
          ano: '2010',
          img: '',
        },
        {
          nome: 'João Carvalho',
          ano: '2011',
          img: '',
        },
        {
          nome: 'Jorge Oliveira',
          ano: '2012',
          img: '',
        },
        {
          nome: 'Paulo Costa',
          ano: '2013',
          img: '',
        },
        {
          nome: 'Paulo Costa',
          ano: '2014',
          img: '',
        },
        {
          nome: 'Pedro Magalhães',
          ano: '2015',
          img: 'PEDRO_MAGALHES.jpg',
        },
        {
          nome: 'Ricardo Barbosa',
          ano: '2016',
          img: 'RICARDO_BARBOSA.jpg',
        },
        {
          nome: 'Daniel Leal',
          ano: '2017',
          img: 'DANIEL_LEAL.jpeg',
        },
        {
          nome: 'Manuel Antunes',
          ano: '2018',
          img: 'MANUEL_ANTUNES.jpg',
        },
        {
          nome: 'Renato Gomes',
          ano: '2019',
          img: 'RENATO_GOMES.jpeg',
        },
        {
          nome: 'André Neves',
          ano: '2020',
          img: 'ANDRE_NEVES.jpg',
        },
        {
          nome: 'Cláudia Fernandes',
          ano: '2021',
          img: 'CLAUDIA_FERNANDES.jpeg',
        },
        
      ]
    }),
    methods: {
      getImgUrl(pic) {
        if(pic == ''){
          return ''
        }
        return require('../assets/presidentes/'+pic)
    }
    }
  }
</script>
