<template>
  <v-main>
    <heads />
       <v-container class="mt-16" >
      <v-row>
        <carousel/>
      </v-row>
      <v-row class="mt-5 mb-16">
        <v-img class="bg_img" src="../assets/galeria/P5181348.jpg" max-height="450px" >
          <v-container style="height:100%">
            <v-row align="center"  style="height:100%">
              <v-spacer></v-spacer>
              <v-col class="col-sm-6 col-lg-3">
                <h2 align="center" justify="center">
                  <v-icon x-large color="orange">fas fa-user-graduate</v-icon></h2>
                <h3 class="white--text text-lg-h4 font-weight-bold" align="center" justify="center">+2000</h3>
                <h5  class="white--text text-lg-h5" align="center" justify="center">Estudantes</h5>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
          </v-container>
        </v-img>
      </v-row>
    </v-container>
    <footers/>
  </v-main>
</template>

<script>
  import heads from '../components/header.vue'
  import carousel from '../components/carousel.vue'
  import footers from '../components/footer.vue'

  export default {
    name: 'Home',

    components: {
      heads,
      footers,
      carousel,
    },
  }
</script>

<style>

.bg_img::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.60);
  z-index: 0;
}
