<template>
  <v-main>
    <heads />
    <v-container class="mt-16" >
      <v-row class="mt-10">
        <v-spacer></v-spacer>  
        <v-col >
          <v-divider color="orange"></v-divider>
          <h2 class="mt-2 text-h4 font-weight-bold" align="center" justify="center">Sobre nós</h2>
        </v-col>
        <v-spacer></v-spacer>  
      </v-row>
      <v-row class="mt-10">
        <v-spacer></v-spacer>  
        <v-col cols="4">
          <p style="text-align: justify; text-justify: inter-word;">A nossa associação nasceu no dia 19 de maio de 1990, quando foram criados, em Assembleia Geral, os Estatutos da Associação de Estudantes da
             Escola Superior de Tecnologia e Gestão.</p>
          <p style="text-align: justify; text-justify: inter-word;">Somos uma equipa constituída por 7 departamentos que têm como objetivo desenvolver atividades para toda a comunidade estudantil,
             procurando integrar alunos de todos as áreas da nossa escola e também isso está relacionado com o símbolo da AE ESTG. Esta foi uma
              imagem criada por dois ex-alunos de Design do Produto e Mestrado Integrado, João Teixeira e Roberto Alves. As linhas onduladas do 
              nosso símbolo permitem transmitir a ideia da pulsação do batimento cardíaco, representando que os alunos são o motor da escola e as
               3 figuras representam, aleatoriamente, os alunos de CTESP, Licenciatura e Mestrado. As suas caras estão em posições diferentes para
                mostrar, que qualquer que seja a tua perspetiva, todas são válidas de ser ouvidas. </p>
          <p style="text-align: justify; text-justify: inter-word;">Ao longo destes anos, procuramos dar sempre o nosso melhor, para proporcionar aos alunos desta escola as ferramentas necessárias para
             o mercado de trabalho, abrindo-lhes portas e estabelecendo contactos, aumentando o espírito académico da escola, incentivando ao desporto,
              criando a responsabilidade social em todos os membros da academia e também promover eventos inclusivos e de diversão aos nossos alunos.</p>
          <p style="text-align: justify; text-justify: inter-word;">A nossa missão será sempre esta, criar uma escola onde todos os alunos se sintam bem e felizes, proporcionando-lhes momentos inesquecíveis.</p>
          <p style="text-align: justify; text-justify: inter-word;">Porque Viana será sempre nossa e nós, seremos sempre de Viana!</p>
        </v-col>
        <v-col cols="4">
          <v-img src="../assets/cor_logo.png" max-height="300px"></v-img>
        </v-col>
        <v-spacer></v-spacer>  
      </v-row>
    </v-container>
    <footers class="mt-10"/>
  </v-main>
</template>

<script>
  import heads from '../components/header.vue'
  import footers from '../components/footer.vue'
  import {gmapApi} from 'vue2-google-maps'

  export default {
    name: 'Contactos',

    components: {
      heads,
      footers,
    },
    computed: {
      google: gmapApi
    }
  }
</script>
