import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Eventos from '../views/Eventos.vue'
import Equipa from '../views/Equipa.vue'
import Contactos from '../views/Contactos.vue'
import Historia from '../views/Historia.vue'
import SobreNos from '../views/SobreNos.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/eventos',
    name: 'Eventos',
    component: Eventos
  },
  {
    path: '/contactos',
    name: 'Contactos',
    component: Contactos
  },
  {
    path: '/equipa',
    name: 'Equipa',
    component: Equipa
  },
  {
    path: '/historia',
    name: 'Historia',
    component: Historia
  },
  {
    path: '/SobreNos',
    name: 'SobreNos',
    component: SobreNos
  }/*
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about"  '../views/About.vue')
    }
  }*/
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
