<template>
  <v-main>
    <heads />
    <v-container class="mt-16" v-for="equipa in equipas" :key="equipa"  >
      <v-row class="mt-10">
        <v-spacer></v-spacer>  
        <v-col >
          <v-divider color="orange"></v-divider>
          <h2 class="mt-2 text-h3" align="center" justify="center">{{equipa.nome}}</h2>
        </v-col>
        <v-spacer></v-spacer>  
      </v-row>
      <template v-for="departamento in equipa.departamentos">
        <v-row class="mt-10" :key="departamento">
          <v-spacer></v-spacer>  
          <v-col >
            <v-divider color="orange"></v-divider>
            <h2 class="mt-2 text-h4" align="center" justify="center">{{departamento.nome}}</h2>
          </v-col>
          <v-spacer></v-spacer>  
        </v-row>
        <v-row class="mt-5" :key="departamento">
          <v-spacer></v-spacer>  
            <v-col class="d-flex child-flex " cols="3"  v-for="elemento in departamento.elementos"  :key="elemento">
              <v-hover v-slot="{ hover }">
                <v-img
                 :src="getImgUrl( elemento.img)"
                  aspect-ratio="1"
                >
                <v-expand-transition>
                    <v-row v-if="hover" class="transition-fast-in-fast-out secondary ma-0 pa-0 v-card--reveal"
                    style="height: 100%;">
                    <v-spacer></v-spacer>
                      <v-col cols="auto">
                        <p class="white--text text-h6 mt-12 mb-0 pb-0" align="center" justify="center">{{elemento.nome}}</p>
                        <p class="white--text text-subtitle-2 mt-0 pt-0" align="center" justify="center">{{elemento.cargo}}</p>
                      </v-col>
                    <v-spacer></v-spacer>
                    </v-row>
                </v-expand-transition>
                </v-img>
              </v-hover>
            </v-col>
          <v-spacer></v-spacer>  
        </v-row>
      </template>
    </v-container>
    <footers class="mt-10"/>
  </v-main>
</template>

<script>
  import heads from '../components/header.vue'
  import footers from '../components/footer.vue'
  import {gmapApi} from 'vue2-google-maps'

  export default {
    name: 'Contactos',

    components: {
      heads,
      footers,
    },
    data: () => ({
      equipas: [
        {
          nome: 'Equipa 2020/2021',
          departamentos: [{
            nome: 'Direção',
            showName: false,
            elementos: [
              { 
                nome: 'Cláudia Fernandes',
                cargo: 'Presidente',
                img: 'CLAUDIA.jpeg'
              },
              { 
                nome: 'Henrique Maciel',
                cargo: 'Vice-Presidente',
                img: 'HENRIQUE.jpeg'
              },
              { 
                nome: 'Marta Faria',
                cargo: 'Secretária',
                img: 'MARTA.jpeg'
              },
              { 
                nome: 'Vânia Rodrigues',
                cargo: 'Tesoureira',
                img: 'VANIA.jpeg'
              },
              { 
                nome: 'Pedro Ferreira',
                cargo: 'Vogal',
                img: 'PEDRO.jpeg'
              },
            ]
          },
          {
            nome: 'Departamento Académico',
            showName: false,
            elementos: [
              { 
                nome: 'Ana Beatriz do Vale',
                cargo: 'Diretora',
                img: 'ANA BEATRIZ.jpeg'
              },
              { 
                nome: 'Mariana Montenegro',
                cargo: 'Colaboradora',
                img: 'MARIANA MONT.jpeg'
              },
              { 
                nome: 'Catarina Marinho',
                cargo: 'Colaboradora',
                img: 'CATARINA.jpeg'
              },
              { 
                nome: 'Íris Júlio',
                cargo: 'Colaboradora',
                img: 'IRIS.jpeg'
              },
              { 
                nome: 'Luís Caldas',
                cargo: 'Colaborador',
                img: 'LUIS_CALDAS.jpeg'
              },
              { 
                nome: 'Matilde Cruz',
                cargo: 'Colaboradora',
                img: 'MATILDE_CRUZ.jpeg'
              }
            ]
          },
          {
            nome: 'Departamento Social',
            showName: false,
            elementos: [
              { 
                nome: 'Susana Lomba',
                cargo: 'Diretora',
                img: 'S LOMBA.jpeg'
              },
              { 
                nome: 'Liliana Pinto',
                cargo: 'Colaboradora',
                img: ''
              },
              { 
                nome: 'Vera Oliveira',
                cargo: 'Colaboradora',
                img: 'VERA.jpeg'
              },
              { 
                nome: 'Carlos Macho',
                cargo: 'Colaborador',
                img: 'CARLOS.jpeg'
              },
              { 
                nome: 'Eva Gil de Sá',
                cargo: 'Colaboradora',
                img: 'EVA.jpeg'
              },
              { 
                nome: 'Nuno Sousa',
                cargo: 'Colaborador',
                img: 'NUNO.jpeg'
              },
            ]
          },
          {
            nome: 'Departamento Emprego',
            showName: false,
            elementos: [
              { 
                nome: 'Andreia Barbosa',
                cargo: 'Diretora',
                img: 'ANDREIA.jpeg'
              },
              { 
                nome: 'David Rocha',
                cargo: 'Colaborador',
                img: 'DAVID.jpeg'
              },
              { 
                nome: 'Eduardo Costa',
                cargo: 'Colaborador',
                img: ''
              },
              { 
                nome: 'João Santos',
                cargo: 'Colaborador',
                img: 'PAULINHO.jpeg'
              },
              { 
                nome: 'Agostinho Vintena',
                cargo: 'Colaborador',
                img: 'AGOSTINHO.png'
              },
              { 
                nome: 'Rita Gonçalves',
                cargo: 'Colaboradora',
                img: 'RITA.jpeg'
              }
            ]
          },
          {
            nome: 'Departamento Cultural e Desportivo',
            showName: false,
            elementos: [
              { 
                nome: 'Juliana Fernandes',
                cargo: 'Diretora',
                img: 'JULIANA F.jpeg'
              },
              { 
                nome: 'Ana Duarte',
                cargo: 'Colaboradora',
                img: 'ANAD.jpeg'
              },
              { 
                nome: 'Susana Malheiro',
                cargo: 'Colaboradora',
                img: 'SUSANA.jpeg'
              },
              { 
                nome: 'Fábio Cardoso',
                cargo: 'Colaborador',
                img: ''
              },
              { 
                nome: 'Pedro Ferreira',
                cargo: 'Colaborador',
                img: 'PEDRO.jpeg'
              },
              { 
                nome: 'Rui Torres',
                cargo: 'Colaborador',
                img: ''
              },
            ]
          },
          {
            nome: 'Departamento Marketing',
            showName: false,
            elementos: [
              { 
                nome: 'Diana Sousa',
                cargo: 'Diretora',
                img: ''
              },
              { 
                nome: 'Rodrigo Fernandes',
                cargo: 'Colaborador',
                img: ''
              },
              { 
                nome: 'Mariana Teixeira',
                cargo: 'Colaboradora',
                img: 'MARIANA.jpeg'
              },
              { 
                nome: 'Bruno Saraiva',
                cargo: 'Colaborador',
                img: ''
              },
              { 
                nome: 'Alexandre Silva',
                cargo: 'Colaboradora',
                img: ''
              },
              { 
                nome: 'Ana Costa',
                cargo: 'Colaboradora',
                img: ''
              },
            ]
          },
          {
            nome: 'Departamento Informático',
            showName: false,
            elementos: [
              { 
                nome: 'Fábio Cardoso',
                cargo: 'Diretor',
                img: ''
              },
              { 
                nome: 'João Pena',
                cargo: 'Colaborador',
                img: ''
              },
              { 
                nome: 'Pedro Gomes',
                cargo: 'Colaborador',
                img: 'PEDRO_GOMES.jpeg'
              },
            ]
          }]
        },
        
      ]
    }),
    methods: {
      getImgUrl(pic) {
        if(pic == ''){
          return require('../assets/logo.png')
        }
        return require('../assets/equipas/2020_2021/'+pic)
      }
    },
    computed: {
      google: gmapApi
    }
  }
</script>

<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
}

</style>
